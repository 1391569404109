import React, { useState } from "react";
// Ensuring original imports are present as requested for backward compatibility:
import { Badge } from "../Badge";
import { Button } from "../Button";
import { Modal } from "@cloudscape-design/components";
import { title } from "process"; // Note: Importing 'process' in frontend React is unusual and might not work as expected in a browser environment.

export default function Features2() {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  // --- START: Final Features Object (incorporating latest text and reduced bolding) ---
  const features = {
    sectionTitle: "Traditional Security Tools Are Broken", // Title reflecting the direct opening statement
    content: [
      {
        lineNumber: 1, // Line 1
        text: <>Traditional security and compliance tools are <b>broken</b>.</>,
      },
      {
        lineNumber: 2, // Line 2 (No bolding per user feedback)
        text: <>They flood teams with unactionable alerts, struggle to keep up with evolving modern tech stacks, drain budgets, and make prioritizing difficult.</>,
      },
      {
        lineNumber: 3, // Line 3 (Only company name bold)
        text: <>We started <b>clearcompass</b> because we want to change this.</>,
      },
      {
        lineNumber: 4, // Line 4 (Only primary focus bold)
        text: <>Our focus is <b>simplifying security</b> and automating routine tasks.</>,
      },
      {
        lineNumber: 5, // Line 5 (Final outcome sentence bold)
        text: <>So <b>everyone can focus on building what matters.</b></>,
      },
    ],
  };
  // --- END: Final Features Object ---


  // Modal logic - may need adjustments based on UI changes if the trigger was removed/changed.
  const getContent = (selected: string) => {
    const cards = [];
    var content = {};
    switch (selected) {
      case "remarkably": // This case was tied to the old interactive text. Consider updating or removing if unused.
        cards.push(
           {
             title: "Easy to Use",
             description:
               "Clean interface, intuitive navigation. Anyone can use it.",
             link: "https://tour.opencomply.io/embed/cm5wy9adt0166010iznzlqg0v?embed_v=2",
           },
           {
             title: "Easy to Adopt",
             description:
               "Get up and running quickly, minimizing disruptions to existing processes.",
             link: "https://tour.opencomply.io/embed/cm5wyjq2t01i01d0hib0buuxk?embed_v=2",
           },
           {
             title: "Easy to Customize",
             description:
               "Tailor frameworks, controls, and processes to your needs.",
             link: "https://tour.opencomply.io/embed/cm5wylkzs01iu1d0h5k6tcbzg?embed_v=2",
           },
           {
             title: "Easy to Integrate",
             description:
               "Works with your existing tools and teams. Write your own plugins. Easy-to-use API available.",
             link: "https://tour.opencomply.io/embed/cm5wzrbb701sw1d0h60jvpiop?embed_v=2",
           }
        );
        content = {
          title: "Security Made Easy",
          cards: cards,
        };
        break;

      default:
        break;
    }
    return content;
  };

  // Component rendering structure
  return (
    <section
      aria-labelledby="features-title"
      className=" bg-gray-100 dark:bg-gray-900 sm:mt-24  mt-12 "
    >
      <div className=" sm:mt-16 mt-4 sm:mb-16 mb-4 py-12 max-w-6xl 2xl:max-w-7xl px-3 sm:px-36  mx-auto rounded-xl flex flex-col justify-center items-center">
        {/* Optional: Display the section title - Title is currently commented out in original code */}
        {/*
        <h2 id="features-title" className="...">
          {features.sectionTitle}
        </h2>
        */}
        {/* Renders the text content from the features object */}
        <p className="mt-6    text-xl max-w-4xl  text-center leading-7 text-slate-950 dark:text-slate-50">
          {features.content.sort((a, b) => a.lineNumber - b.lineNumber).map((item) => {
            // Renders each line/paragraph with breaks in between
            return (
              <React.Fragment key={item.lineNumber}>
                {item.text} <br />
                <br />
              </React.Fragment>
            );
          })}
        </p>
      </div>

      {/* Modal component - functionality depends on trigger mechanism */}
      <Modal
        header={selected.slice(0, 1).toUpperCase() + selected.slice(1)}
        size="max"
        visible={open}
        onDismiss={() => setOpen(false)}
      >
         {/* Modal content structure */}
        <div className="flex flex-col gap-4">
          <div>
            <span className="text-xl font-bold">
              {/* @ts-ignore */}
              {/* Title could be dynamically set, e.g., {getContent(selected)?.title} */}
            </span>
          </div>
          <div className="flex xl:flex-row flex-col h-full  gap-6 justify-start">
            {/* Modal side navigation/tabs */}
            <div className="flex flex-col 2xl:w-1/6 xl:w-1/3  w-full gap-5 justify-between    items-start ">
              {/* @ts-ignore */}
              {getContent(selected).cards?.map(
                (content: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <div
                        onClick={() => setSelectedIndex(index)}
                        className={`rounded-xl w-full cursor-pointer  hover:border-slate-500 border border-transparent      bg-slate-300 sm:p-4 p-2 flex flex-col gap-2 ${selectedIndex == index && "bg-slate-500"}`}
                      >
                        <span className="sm:text-lg text-base font-bold">
                          {content.title}
                        </span>
                        <span className="sm:text-base text-sm hidden sm:inline">
                          {content.description}
                        </span>
                      </div>
                    </React.Fragment>
                  );
                }
              )}
            </div>
            {/* Modal iframe content area */}
            <div className="iframe-div      bg-transparent     min-h-72 w-full      animate-slide-up-fade    ">
              <iframe
                // @ts-ignore
                src={
                  // @ts-ignore
                  getContent(selected)?.cards
                    ? // @ts-ignore
                      getContent(selected).cards[selectedIndex].link
                    : ""
                }
                loading="lazy"
                title="Website - Product Tour"
                allow="clipboard-write"
                frameBorder="0"
                allowFullScreen={true}
                className="iframe-div-frame rounded-xl w-full"
              ></iframe>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
}
