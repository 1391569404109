import Code from "../Code";
import {
  RiBarcodeBoxLine,
  RiBattery2ChargeFill,
  RiBox1Line,
  RiBracesLine,
  RiCloudLine,
  RiCodeBlock,
  RiCodeBoxLine,
  RiContractUpDownLine,
  RiDatabaseLine,
  RiGitBranchLine,
  RiGitMergeLine,
  RiGroup2Line,
  RiGroupLine,
  RiLink,
  RiLinksLine,
  RiLockStarLine,
  RiOpenSourceLine,
  RiP2pLine,
  RiPlugLine,
  RiRocketLine,
  RiShieldKeyholeLine,
  RiShieldStarLine,
  RiShieldUserLine,
  RiStackLine,
} from "@remixicon/react";
import { Badge } from "../Badge";
import CodeExampleTabs from "./CodeExampleTabs";
import { TabContent } from "./CodeTabContent";
import { title } from "process";
import ThemedImage from "./ThemedImage";

export const features = {
  title: "Security that works for you",
  subtitle:
    "Understand your stack. Strengthen security. Automate routine tasks.",
  "bullet-points": [
    {
      "key-title": "Open",
      icon: RiGitMergeLine,
      description:
        "Open platform, source, and design. Easily create your own plugins and checks.",
    },
    {
      "key-title": "Easy to Deploy",
      icon: RiRocketLine,
      description:
        "Kubernetes-native and easy to deploy. Get started in minutes.",
    },
    {
      "key-title": "Engineer-Friendly",
      icon: RiCodeBoxLine,
      description:
        "Git-managed policies. Pipeline Integration. Automate checks, enforce best practices.",
    },
    {
      "key-title": "Battle Ready",
      icon: RiBattery2ChargeFill,
      description: "Built for scale with enterprise-grade security features including SSO, RBAC, and API support.",
    }

  ],
};






export default function CodeExample() {
  return (
    <section
      aria-labelledby="code-example-title"
      className="mx-auto sm:mt-0 sm:pb-28 mt-4 w-full  px-3 flex flex-col justify-center items-center "
    >
      <div className="w-full max-w-6xl 2xl:max-w-7xl mt-12 flex flex-col justify-center items-center">
        <h2
          id="code-example-title"
          className="mt-2 inline-block bg-gradient-to-br from-gray-900 to-gray-800 bg-clip-text py-2 text-3xl font-bold tracking-tighter text-transparent sm:text-5xl md:text-5xl dark:from-gray-50 dark:to-gray-300"
        >
          {features.title}
        </h2>
        <p className="mt-6 max-w-2xl text-lg sm:inline-block hidden text-gray-900 dark:text-gray-400">
          {features.subtitle}
        </p>
        <CodeExampleTabs
          tab1={<TabContent  />}
          tab2={
            <>
              <>
                <div className="h-full w-full flex justify-center items-center ">
                  <img
                    height={"700px"}
                    className=" rounded-xl w-full  "
                    src="https://content.opencomply.io/website/product-screenshots/unified-governance-2.png"
                  />
                </div>
              </>
            </>
          }
          tab3={
            <>
              <div className="h-full w-full flex justify-center items-center ">
                <img
                  className=" rounded-xl w-full  "
                  src="https://content.opencomply.io/website/product-screenshots/unlimited-adaptability-control.png"
                />
              </div>
            </>
          }
        />

        <dl className="sm:mt-24 mt-4 grid grid-cols-4 gap-10 sm:p-0 p-3">
          {features["bullet-points"].map((item) => (
            <div
              key={item["key-title"]}
              className="col-span-full sm:col-span-2 lg:col-span-1"
            >
              <div className="w-fit rounded-lg p-2 shadow-md shadow-indigo-400/30 ring-1 ring-black/5 dark:shadow-indigo-600/30 dark:ring-white/5">
                <item.icon
                  aria-hidden="true"
                  className="size-6 text-indigo-600 dark:text-indigo-400"
                />
              </div>
              <dt className="mt-6 font-semibold text-gray-900 dark:text-gray-50">
                {item["key-title"]}
              </dt>
              <dd className="mt-2 leading-7 text-gray-600 dark:text-gray-400">
                {item.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </section>
  );
}
