export const Results = [
  {
    title: "Kubernetes",
    description: "Kubernetes Clusters running on AWS, Azure, and DigitalOcean",
    logos: [
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/aws.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/azure.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/digitalocean.svg",
    ],
    plugins: [
      { name: "AWS", url: "https://opencomply.io/integrations/aws/schema" },
      { name: "Azure", url: "https://opencomply.io/integrations/azure/schema" },
      {
        name: "Digital Ocean",
        url: "https://opencomply.io/integrations/digitalocean/schema",
      },
    ],
    query: `
SELECT cluster_name AS "Cluster Name",
       provider AS "Provider",
       version AS "Version",
       status AS "Status"
FROM kubernetes_clusters
WHERE provider IN ('AWS', 'Azure', 'DigitalOcean')
ORDER BY version DESC;`,
    results: {
      columns: ["Provider", "Cluster Name", "Version", "Status"],
      rows: [
        {
          Provider: "AWS",
          "Cluster Name": "eks-demo",
          Version: "1.31",
          Status: "Active",
        },
        {
          Provider: "AWS",
          "Cluster Name": "eks-staging-demo",
          Version: "1.29",
          Status: "Active",
        },
        {
          Provider: "digitalocean",
          "Cluster Name": "pilot-staging-demo",
          Version: "1.29",
          Status: "Active",
        },
        {
          Provider: "digitalocean",
          "Cluster Name": "phoenix-staging-demo",
          Version: "1.29",
          Status: "Active",
        },
        {
          Provider: "azure",
          "Cluster Name": "iceberg-poc",
          Version: "1.31",
          Status: "Active",
        },
      ],
    },
  },
  {
    title: "Production Databases",
    description: "Databases tagged as Prod in Render & Linode",
    logos: [
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/render.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/linode.svg",
    ],
    plugins: [
      {
        name: "Linode",
        url: "https://opencomply.io/integrations/linode/schema",
      },
      {
        name: "Render",
        url: "https://opencomply.io/integrations/render/schema",
      },
    ],
    query: `
select * from linode_database`,
    results: {
      columns: ["Provider", "Database Name", "Public Endpoint", "Status"],
      rows: [
        {
          Provider: "Render",
          "Database Name": "cust-data-db",
          "Public Endpoint": "cust-data-db.xxxx..onrender.com",
          Status: "Public",
        },
        {
          Provider: "Linode",
          "Database Name": "sales-analytics",
          "Public Endpoint": "171.23.56.69",
          Status: "Public",
        },
        {
          Provider: "Render",
          "Database Name": "inventory-db",
          "Public Endpoint": "inventory-db.onrender.com",
          Status: "Public",
        },
        {
          Provider: "Linode",
          "Database Name": "test-metrics",
          "Public Endpoint": "18.12.36.154",
          Status: "Public",
        },
        {
          Provider: "Render",
          "Database Name": "catalog-poc",
          "Public Endpoint": "catalog-poc.database.onrender.com",
          Status: "Public",
        },
      ],
    },
  },
  {
    title: "AI Models",
    description: "AI Models in use across OpenAI & Cohere projects.",
    logos: [
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/openai.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/cohereai.svg",
    ],
    plugins: [
      {
        name: "openAI",
        url: "https://opencomply.io/integrations/openai/schema",
      },
      {
        name: "CohereAI",
        url: "https://opencomply.io/integrations/cohereai/schema",
      },
    ],
    query: `
SELECT * from openai_assistant`,
    results: {
      columns: ["Model Name", "Provider", "Version", "Usage"],
      rows: [
        {
          "Model Name": "GPT-4",
          Provider: "OpenAI",
          Version: "4.0",
          Usage: "Advanced Chat & Code Generation",
        },
        {
          "Model Name": "GPT-3.5",
          Provider: "OpenAI",
          Version: "3.5",
          Usage: "General Text Completion",
        },
        {
          "Model Name": "Command-Native",
          Provider: "Cohere",
          Version: "1.2",
          Usage: "Conversational AI",
        },
        {
          "Model Name": "Embed-XL",
          Provider: "Cohere",
          Version: "1.0",
          Usage: "Text Embeddings",
        },
        {
          "Model Name": "GPT-3 Ada",
          Provider: "OpenAI",
          Version: "3.0",
          Usage: "Lightweight Prompting",
        },
      ],
    },
  },
  {
    title: "Cloud MFA Access",
    description: "Users with AWS and Azure Access without MFA",
    logos: [
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/aws.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/azure.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/gcp.svg",
    ],
    plugins: [
      { name: "AWS", url: "https://opencomply.io/integrations/aws/schema" },
      { name: "Azure", url: "https://opencomply.io/integrations/azure/schema" },
      {
        name: "Google Work Space",
        url: "https://opencomply.io/integrations/googleworkspace/schema",
      },
    ],
    query: `
SELECT u.user_principal_name AS "UserPrincipalName",
       u.display_name AS "UserDisplayName",
       r.is_mfa_capable AS "MFA Capable",
       r.is_mfa_registered AS "IsMfaRegistered",
       r.is_system_preferred_authentication_method_enabled AS "IsSystemPreferredAuthenticationMethodEnabled",
       COUNT(DISTINCT a.subscription_id) AS "Number of Subscriptions"
    FROM entraid_user u
        JOIN entraid_user_registration_details r
            ON u.id = r.id
        JOIN azure_role_assignment a
            ON u.id = a.principal_id
    WHERE a.subscription_id IS NOT NULL 
    GROUP BY u.user_principal_name,
             u.display_name,
             r.is_mfa_capable,
             r.is_mfa_registered,
             r.is_system_preferred_authentication_method_enabled
    ORDER BY COUNT(DISTINCT a.subscription_id) DESC;`,
    results: {
      columns: [
        "Email",
        "Name",
        "MFA Capable",
        "IsMfaRegistered",
        "ApprovedMFA",
        "Cloud Accounts",
      ],
      rows: [
        {
          Email: "john.doe@demo.org",
          Name: "John Doe",
          "MFA Capable": "No",
          IsMfaRegistered: "No",
          ApprovedMFA: "No",
          "Cloud Accounts": 4,
        },
        {
          Email: "emma.chan@demo.org",
          Name: "Emma Chan",
          "MFA Capable": "Yes",
          IsMfaRegistered: "No",
          ApprovedMFA: "No",
          "Cloud Accounts": 3,
        },
        {
          Email: "alex.smith@demo.org",
          Name: "Alex Smith",
          "MFA Capable": "Yes",
          IsMfaRegistered: "Yes",
          ApprovedMFA: "Yes",
          "Cloud Accounts": 3,
        },
        {
          Email: "mfernandez@demo.org",
          Name: "Maria Fernandez",
          "MFA Capable": "No",
          IsMfaRegistered: "No",
          ApprovedMFA: "No",
          "Cloud Accounts": 2,
        },
        {
          Email: "devon.lee@demo.org",
          Name: "Devon Lee",
          "MFA Capable": "Yes",
          IsMfaRegistered: "No",
          ApprovedMFA: "No",
          "Cloud Accounts": 1,
        },
      ],
    },
  },
  {
    title: "Container Images",
    description: "Container Base Images across all Repositories",
    logos: [
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/github.svg",
    ],
    plugins: [
      {
        name: "Github",
        url: "https://opencomply.io/integrations/github/schema",
      },
    ],
    query: `
SELECT image AS "Base Image",
COUNT(*) AS "Count" 
FROM ( 
  SELECT DISTINCT sha, jsonb_array_elements_text(images) AS image 
  FROM github_artifact_dockerfile 
) AS expanded 
GROUP BY image 
ORDER BY "Count" DESC;`,
    results: {
      columns: ["Base Image", "Count"],
      rows: [
        { "Base Image": "scratch", Count: 14 },
        { "Base Image": "docker.io/golang:alpine", Count: 14 },
        { "Base Image": "cloudql-plugin-base:0.0.1", Count: 6 },
        { "Base Image": "golang:1.23-alpine", Count: 4 },
        { "Base Image": "alpine:latest", Count: 3 },
        { "Base Image": "alpine:edge", Count: 2 },
        { "Base Image": "ubuntu:20.04", Count: 2 },
        { "Base Image": "node:18-alpine", Count: 2 },
        { "Base Image": "node:22-alpine", Count: 1 },
        { "Base Image": "postgres:14.13", Count: 1 },
        { "Base Image": "postgres:17.2", Count: 1 },
        { "Base Image": "cloudql-plugin-aws:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-azure:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-cloudflare:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-cohereai:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-digitalocean:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-doppler:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-entraid:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-github:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-googleworkspace:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-linode:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-oci:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-openai:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-render:0.0.1", Count: 1 },
        { "Base Image": "alpine:3.20", Count: 1 },
        { "Base Image": "ubuntu:latest", Count: 1 },
        { "Base Image": "aquasec/trivy:0.57.1", Count: 1 },
        { "Base Image": "bitnami/postgresql:latest", Count: 1 },
        { "Base Image": "cloudql:0.0.1", Count: 1 },
        { "Base Image": "docker.io/nginx:1.25.0-alpine", Count: 1 },
        {
          "Base Image": "docker.mirror.hashicorp.services/golang:alpine",
          Count: 1,
        },
        { "Base Image": "ghcr.io/dexidp/dex:latest", Count: 1 },
        { "Base Image": "golang:1.23.1-alpine", Count: 1 },
      ],
    },
  },
  {
    title: "Publicly Accessible Databases",
    description:
      "Databases in AWS, Azure, and Render that are publicly accessible",
    logos: [
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/aws.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/azure.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/render.svg",
    ],
    plugins: [
      { name: "AWS", url: "https://opencomply.io/integrations/aws/schema" },
      { name: "Azure", url: "https://opencomply.io/integrations/azure/schema" },
      {
        name: "Render",
        url: "https://opencomply.io/integrations/render/schema",
      },
    ],
    query: `
SELECT provider AS "Provider",
       db_name AS "Database Name",
       public_endpoint AS "Public Endpoint",
       status AS "Status"
FROM databases
WHERE status = 'Public'
ORDER BY provider ASC;`,
    results: {
      columns: ["Provider", "Database Name", "Public Endpoint", "Status"],
      rows: [
        {
          Provider: "AWS",
          "Database Name": "cust-data-db",
          "Public Endpoint": "cust-data-db.xxxx.amazonaws.com",
          Status: "Public",
        },
        {
          Provider: "Azure",
          "Database Name": "sales-analytics",
          "Public Endpoint": "sales-analytics.database.windows.net",
          Status: "Public",
        },
        {
          Provider: "Render",
          "Database Name": "inventory-db",
          "Public Endpoint": "inventory-db.onrender.com",
          Status: "Public",
        },
        {
          Provider: "AWS",
          "Database Name": "test-metrics",
          "Public Endpoint": "test-metrics.xxxx.amazonaws.com",
          Status: "Public",
        },
        {
          Provider: "Azure",
          "Database Name": "catalog-poc",
          "Public Endpoint": "catalog-poc.database.windows.net",
          Status: "Public",
        },
      ],
    },
  },
  {
    title: "Cloud MFA Access",
    description: "Users with AWS and Azure Access without MFA",
    logos: [
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/aws.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/azure.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/gcp.svg",
    ],
    plugins: [
      { name: "AWS", url: "https://opencomply.io/integrations/aws/schema" },
      { name: "Azure", url: "https://opencomply.io/integrations/azure/schema" },
      {
        name: "Google Work Space",
        url: "https://opencomply.io/integrations/googleworkspace/schema",
      },
    ],
    query: `
SELECT u.user_principal_name AS "UserPrincipalName",
       u.display_name AS "UserDisplayName",
       r.is_mfa_capable AS "MFA Capable",
       r.is_mfa_registered AS "IsMfaRegistered",
       r.is_system_preferred_authentication_method_enabled AS "IsSystemPreferredAuthenticationMethodEnabled",
       COUNT(DISTINCT a.subscription_id) AS "Number of Subscriptions"
    FROM entraid_user u
        JOIN entraid_user_registration_details r
            ON u.id = r.id
        JOIN azure_role_assignment a
            ON u.id = a.principal_id
    WHERE a.subscription_id IS NOT NULL 
    GROUP BY u.user_principal_name,
             u.display_name,
             r.is_mfa_capable,
             r.is_mfa_registered,
             r.is_system_preferred_authentication_method_enabled
    ORDER BY COUNT(DISTINCT a.subscription_id) DESC;`,
    results: {
      columns: [
        "Email",
        "Name",
        "MFA Capable",
        "IsMfaRegistered",
        "ApprovedMFA",
        "Cloud Accounts",
      ],
      rows: [
        {
          Email: "john.doe@demo.org",
          Name: "John Doe",
          "MFA Capable": "No",
          IsMfaRegistered: "No",
          ApprovedMFA: "No",
          "Cloud Accounts": 4,
        },
        {
          Email: "emma.chan@demo.org",
          Name: "Emma Chan",
          "MFA Capable": "Yes",
          IsMfaRegistered: "No",
          ApprovedMFA: "No",
          "Cloud Accounts": 3,
        },
        {
          Email: "alex.smith@demo.org",
          Name: "Alex Smith",
          "MFA Capable": "Yes",
          IsMfaRegistered: "Yes",
          ApprovedMFA: "Yes",
          "Cloud Accounts": 3,
        },
        {
          Email: "mfernandez@demo.org",
          Name: "Maria Fernandez",
          "MFA Capable": "No",
          IsMfaRegistered: "No",
          ApprovedMFA: "No",
          "Cloud Accounts": 2,
        },
        {
          Email: "devon.lee@demo.org",
          Name: "Devon Lee",
          "MFA Capable": "Yes",
          IsMfaRegistered: "No",
          ApprovedMFA: "No",
          "Cloud Accounts": 1,
        },
      ],
    },
  },
  {
    title: "Container Images",
    description: "Container Base Images across all Repositories",
    logos: [
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/github.svg",
    ],
    plugins: [
      {
        name: "Github",
        url: "https://opencomply.io/integrations/github/schema",
      },
    ],
    query: `
SELECT image AS "Base Image",
COUNT(*) AS "Count" 
FROM ( 
  SELECT DISTINCT sha, jsonb_array_elements_text(images) AS image 
  FROM github_artifact_dockerfile 
) AS expanded 
GROUP BY image 
ORDER BY "Count" DESC;`,
    results: {
      columns: ["Base Image", "Count"],
      rows: [
        { "Base Image": "scratch", Count: 14 },
        { "Base Image": "docker.io/golang:alpine", Count: 14 },
        { "Base Image": "cloudql-plugin-base:0.0.1", Count: 6 },
        { "Base Image": "golang:1.23-alpine", Count: 4 },
        { "Base Image": "alpine:latest", Count: 3 },
        { "Base Image": "alpine:edge", Count: 2 },
        { "Base Image": "ubuntu:20.04", Count: 2 },
        { "Base Image": "node:18-alpine", Count: 2 },
        { "Base Image": "node:22-alpine", Count: 1 },
        { "Base Image": "postgres:14.13", Count: 1 },
        { "Base Image": "postgres:17.2", Count: 1 },
        { "Base Image": "cloudql-plugin-aws:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-azure:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-cloudflare:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-cohereai:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-digitalocean:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-doppler:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-entraid:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-github:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-googleworkspace:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-linode:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-oci:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-openai:0.0.1", Count: 1 },
        { "Base Image": "cloudql-plugin-render:0.0.1", Count: 1 },
        { "Base Image": "alpine:3.20", Count: 1 },
        { "Base Image": "ubuntu:latest", Count: 1 },
        { "Base Image": "aquasec/trivy:0.57.1", Count: 1 },
        { "Base Image": "bitnami/postgresql:latest", Count: 1 },
        { "Base Image": "cloudql:0.0.1", Count: 1 },
        { "Base Image": "docker.io/nginx:1.25.0-alpine", Count: 1 },
        {
          "Base Image": "docker.mirror.hashicorp.services/golang:alpine",
          Count: 1,
        },
        { "Base Image": "ghcr.io/dexidp/dex:latest", Count: 1 },
        { "Base Image": "golang:1.23.1-alpine", Count: 1 },
      ],
    },
  },
  {
    title: "Publicly Accessible Databases",
    description:
      "Databases in AWS, Azure, and Render that are publicly accessible",
    logos: [
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/aws.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/azure.svg",
      "https://raw.githubusercontent.com/opengovern/website/main/connectors/icons/render.svg",
    ],
    plugins: [
      { name: "AWS", url: "https://opencomply.io/integrations/aws/schema" },
      { name: "Azure", url: "https://opencomply.io/integrations/azure/schema" },
      {
        name: "Render",
        url: "https://opencomply.io/integrations/render/schema",
      },
    ],
    query: `
SELECT provider AS "Provider",
       db_name AS "Database Name",
       public_endpoint AS "Public Endpoint",
       status AS "Status"
FROM databases
WHERE status = 'Public'
ORDER BY provider ASC;`,
    results: {
      columns: ["Provider", "Database Name", "Public Endpoint", "Status"],
      rows: [
        {
          Provider: "AWS",
          "Database Name": "cust-data-db",
          "Public Endpoint": "cust-data-db.xxxx.amazonaws.com",
          Status: "Public",
        },
        {
          Provider: "Azure",
          "Database Name": "sales-analytics",
          "Public Endpoint": "sales-analytics.database.windows.net",
          Status: "Public",
        },
        {
          Provider: "Render",
          "Database Name": "inventory-db",
          "Public Endpoint": "inventory-db.onrender.com",
          Status: "Public",
        },
        {
          Provider: "AWS",
          "Database Name": "test-metrics",
          "Public Endpoint": "test-metrics.xxxx.amazonaws.com",
          Status: "Public",
        },
        {
          Provider: "Azure",
          "Database Name": "catalog-poc",
          "Public Endpoint": "catalog-poc.database.windows.net",
          Status: "Public",
        },
      ],
    },
  },
];
