import { useParams } from "react-router-dom";

import React, { Fragment, useEffect, useState } from "react";
import Balancer from "react-wrap-balancer";
import ReactMarkdown from "react-markdown";
import aws from "./aws.md"
import azure from "./azure.md";
import cloudflare from "./cloudflare.md";
import digitalocean from "./digitalocean.md";
import entraid from "./entraid.md";
import linode from "./linode.md";
import googleworkspace from "./googleworkspace.md";
import github from "./github.md"
import rehypeRaw from "rehype-raw";
import render from './render.md'
import openai from './openai.md'
import cohereai from './cohereai.md'
import kubernetes from './kubernetes.md'
import fly from './fly.md'
const setupFiles = {
  aws,
  azure,
  cloudflare,
  digitalocean,
  entraid,
  linode,
  googleworkspace,
  github,
  render,
  openai,
  cohereai,
  kubernetes,
  fly
};


export default function Setup() {
const { id } = useParams();
const [file, setFile] = useState<string>();
const getFile = () => {
  // @ts-ignore
  if (id && setupFiles[id]) {
    // @ts-ignore
    fetch(setupFiles[id]).then((res) => {
      res.text().then((text) => {
        setFile(text);
      });
    });
  }
}
useEffect(()=>{
    getFile();
},[])

  return (
    <div className="mx-auto pt-2 max-w-6xl">
      <div className="text-center">
        <h1 className="inline-block bg-gradient-to-t from-gray-900 to-gray-800 bg-clip-text py-2 text-4xl font-bold tracking-tighter text-transparent sm:text-5xl dark:from-gray-50 dark:to-gray-300">
          Setup {id?.split("_")[0].toLocaleUpperCase()}
        </h1>
      </div>
      <div className="mt-5 markdown-container">
        <ReactMarkdown
          // @ts-ignore
          children={file}
          skipHtml={false}
          className={"markdown-body"}
          rehypePlugins={[rehypeRaw]}
        />
      </div>
    </div>
  );
}
