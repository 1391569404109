import { Button } from "../../components/Button";
import { ArrowAnimated } from "../../components/ui/ArrowAnimated";
import {
  RiCheckLine,
  RiCloudLine,
  RiInformationLine,
  RiSubtractLine,
  RiUserLine,
} from "@remixicon/react";
import React, { Fragment } from "react";
import Cal from "@calcom/embed-react";
import { Modal } from "@cloudscape-design/components";
import { FaqsPrice } from "../../components/ui/faqPrice";
type FixedPrice = string;

interface VariablePrice {
  monthly: string;
  annually: string;
}

interface Plan {
  name: string;
  price: FixedPrice | VariablePrice;
  description: string;
  features: string[];
  isStarter: boolean;
  isRecommended: boolean;
  buttonText: string;
  buttonLink: string;
}

const plans: Plan[] = [
  {
    name: "Community",
    price: "$0",
    description:
      "Ideal for small teams and startups that are comfortable managing updates themselves.",
    features: [
      "Unlimited Users",
      "Unlimited Integrations",
      "Web UI & API",
      "Single Sign-On (SSO)",
      "50+ Built-In Frameworks",
      "Limited Audit Trails",
    ],
    isStarter: true,
    isRecommended: false,
    buttonText: "Download Now",
    buttonLink: "https://docs.opencomply.io/oss#deploy-to-kubernetes-5-7-mins",
  },
  {
    name: "Professional",
    price: { monthly: "$49", annually: "$39" },
    description:
      "For growing teams that need SaaS convenience, seamless upgrades, and premium integrations.",
    features: [
      "Everything in Community",
      "SaaS with Seamless Upgrades",
      "Access up to 5 Premium Integrations",
      "Data Controls on CloudQL",
      "Up to 365 Days of Audit History",
      "Email & Chat Support",
    ],
    isStarter: false,
    isRecommended: false,
    buttonText: "Request Trial",
    buttonLink: "#",
  },
  {
    name: "Enterprise",
    price: { monthly: "$99", annually: "$79" },
    description:
      "Ideal for organizations that need advanced permissions, flexible hosting, and robust audit trails.",
    features: [
      "Everything in Professional",
      "Granular Permissions & Data Controls",
      "Access to 45+ Premium Integrations",
      "SLAs & Phone Support",
      "Cloud or Managed Hosting",
      "Extended Audit History (up to 1100 days)",
    ],
    isStarter: false,
    isRecommended: false,
    buttonText: "Request Trial",
    buttonLink: "#",
  },
];

interface Feature {
  name: string;
  plans: Record<string, boolean | string>;
  tooltip?: string;
}

interface Section {
  name: string;
  features: Feature[];
}



export default function Pricing() {

  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Modal
        header="Try enterprise Edition"
        size="large"
        visible={open}
        onDismiss={() => setOpen(false)}
      >
        <Cal
          namespace="try"
          calLink="team/clearcompass/try"
          style={{ width: "100%", height: "100%", overflow: "scroll" }}
          config={{ layout: "month_view" }}
        />
      </Modal>
      <div className="mx-auto pt-36 max-w-6xl">
        <div className="px-3">
          <section
            aria-labelledby="pricing-title "
            className="animate-slide-up-fade flex justify-center items-center flex-col"
            style={{
              animationDuration: "600ms",
              animationFillMode: "backwards",
            }}
          >
            {/* <Badge>Pricing</Badge> */}
            <h1 className="mt-2 inline-block bg-gradient-to-br from-gray-900 to-gray-800 bg-clip-text py-1 text-4xl font-bold tracking-tighter text-transparent sm:text-6xl md:text-6xl dark:from-gray-50 dark:to-gray-300">
              Pricing
            </h1>
            <p className=" max-w-lg text-lg text-center text-gray-700 dark:text-gray-400">
              We're here to help you succeed.
              <br />
              Explore our flexible pricing plans designed to grow with you.
            </p>
          </section>
          <section
            id="pricing-overview"
            className="mt-10 animate-slide-up-fade"
            aria-labelledby="pricing-overview"
            style={{
              animationDuration: "600ms",
              animationDelay: "200ms",
              animationFillMode: "backwards",
            }}
          >
            <div className="grid grid-cols-1 gap-x-14 gap-y-8 lg:grid-cols-3">
              {plans.map((plan, planIdx) => (
                <div key={planIdx} className="mt-6">
                  {plan.isRecommended ? (
                    <div className="flex h-4 items-center">
                      <div className="relative w-full">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-indigo-600 dark:border-indigo-400" />
                        </div>
                        <div className="relative flex justify-center">
                          <span className="bg-white px-3 text-xs font-medium text-indigo-600 dark:bg-gray-950 dark:text-indigo-400">
                            Most popular
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex h-4 items-center">
                      <div className="h-px w-full bg-gray-200 dark:bg-gray-800" />
                    </div>
                  )}
                  <div className="mx-auto max-w-md">
                    <h2 className="text-3xl font-semibold tabular-nums text-gray-900 dark:text-gray-50 mt-6">
                      {plan.name}
                    </h2>

                    <div className="mt-6 flex flex-col justify-between">
                      <p className="text-sm leading-6 text-gray-600 dark:text-gray-400">
                        {plan.description}
                      </p>
                      <div className="mt-6">
                        {plan.isStarter ? (
                          <Button variant="secondary" asChild className="group">
                            <a href={plan.buttonLink} target="__blank">
                              {plan.buttonText}
                              <ArrowAnimated />
                            </a>
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setOpen(true);
                            }}
                            asChild
                            className="group"
                          >
                            <a href={plan.buttonLink}>
                              {plan.buttonText}
                              <ArrowAnimated />
                            </a>
                          </Button>
                        )}
                      </div>
                    </div>

                    <ul
                      role="list"
                      className="mt-4 text-sm text-gray-700 dark:text-gray-400"
                    >
                      {plan.features.map((feature) => (
                        <li
                          key={feature}
                          className="flex items-center gap-x-3 py-1.5"
                        >
                          <RiCheckLine
                            className="size-4 shrink-0 text-indigo-600 dark:text-indigo-400"
                            aria-hidden="true"
                          />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
      <section
        aria-labelledby="pricing-title "
        className="animate-slide-up-fade flex justify-center items-center flex-col mt-20 py-20 custom-container "
        style={{
          animationDuration: "600ms",
          animationFillMode: "backwards",
        }}
      >
        <h1 className="mt-2 inline-block bg-gradient-to-br text-white bg-clip-text py-1 text-3xl font-bold tracking-tighter text-transparent sm:text-6xl md:text-6xl dark:from-gray-50 dark:to-gray-300">
          Ready to get started?
        </h1>

        <Button
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
          variant="primary"
          className="group mt-7"
        >
          Get in touch
          <ArrowAnimated />
        </Button>
      </section>
      <FaqsPrice />
    </>
  );
}
