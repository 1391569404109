import { RiPlayCircleFill } from "@remixicon/react";
import HeroImage from "./HeroImage";
// @ts-ignore
import video from "../../videos/2024-10-08-How_to_Customize_Controls.mp4";
import { ProgressBar } from "@tremor/react";
import { useEffect, useState } from "react";
import { DISCOVER_URL } from "../../pages/landing/urls";
import { AUDIT_URL } from "../../pages/landing/urls";
import { CUSTOMIZE_URL } from "../../pages/landing/urls";
import { Button } from "../Button";
import { Modal } from "@cloudscape-design/components";
import Cal from "@calcom/embed-react";
const URLS = {
  0: DISCOVER_URL,
  1: AUDIT_URL,
  2: CUSTOMIZE_URL,
};

export default function Hero({setOpen} :any) {

  return (
    <section
      aria-labelledby="hero-title"
      className="mt-32 flex  flex-col items-center justify-center text-center sm:mt-40 sm:p-0 p-2"
    >
      <h1
        id="hero-title"
        className="sm:inline-block hidden animate-slide-up-fade bg-gradient-to-br from-gray-900 to-gray-800 bg-clip-text p-2 text-base font-semibold tracking-tight text-transparent sm:text-4xl md:text-4xl dark:from-gray-50 dark:to-gray-300"
        style={{ animationDuration: "700ms" }}
      >
        Find and Fix
        Security Vulnerabilities Automatically
      </h1>
      <p
        className="mt-1 max-w-3xl animate-slide-up-fade text-2xl text-gray-700 dark:text-gray-400"
        style={{ animationDuration: "900ms" }}
      >
        <strong>opensecurity</strong> from clearcompass automatically scans
        containers, Kubernetes, and cloud configuration for security issues.
      </p>
      <br />
      <p
        className="mt-1 max-w-3xl sm:inline-block hidden animate-slide-up-fade text-1xl text-gray-700 dark:text-gray-200"
        style={{ animationDuration: "900ms" }}
      >
        AI agents automatically fix and remediate vulnerabilities. 
      </p>
      <div
        className="mt-8 flex w-full animate-slide-up-fade flex-col justify-center items-center gap-3 px-3 sm:flex-row"
        style={{ animationDuration: "1100ms" }}
      >
        <a
          href="https://docs.opencomply.io"
          className=" border rounded-sm w-full max-w-52 border-indigo-600 dark:border-indigo-500 p-2 text-black dark:text-white elative inline-flex items-center justify-center whitespace-nowrap   px-3 py-2 text-center text-sm font-medium shadow-sm transition-all duration-100 ease-in-out"
          target="__blank"
        >
          Get Started with Open Source
        </a>
        <Button
          onClick={() => {
            setOpen(true);
          }}
          className=" font-semibold rounded-sm w-full max-w-52 "
        >
          Try Automated Remediation
        </Button>
      </div>
      <div className="iframe-div hidden sm:inline relative sm:mx-auto mx-3 bg-transparent  mt-20 h-fit  max-w-5xl 2xl:max-w-6xl animate-slide-up-fade sm:ml-auto sm:w-full ">
        <iframe
          src="https://tour.opencomply.io/embed/cm6pbtjeo0041peca23z7fy84?embed_v=2"
          loading="lazy"
          title="Website - Product Tour"
          allow="clipboard-write"
          frameBorder="0"
          allowFullScreen={true}
          className="iframe-div-frame rounded-xl"
        ></iframe>
      </div>
      <div className="rounded-xl sm:hidden flex relative sm:mx-auto mx-3 bg-transparent  mt-20 h-fit  max-w-5xl 2xl:max-w-6xl animate-slide-up-fade sm:ml-auto sm:w-full bg-white ring-1 ring-slate-900/5 dark:bg-slate-950 dark:ring-white/15">
        <video
          id="hero-video"
          className="rounded-xl w-full shadow-2xl dark:shadow-indigo-600/10"
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          // @ts-ignore
          src={
            "https://content.opencomply.io/website/product-videos/product-tour-mobile.mp4"
          }
        >
          <source
            src={
              // @ts-ignore
              "https://content.opencomply.io/website/product-videos/product-tour-mobile.mp4"
            }
            type="video/mp4"
          />
        </video>
      </div>
    </section>
  );
}
