//@ts-nocheck
import * as React from "react"
import type { SVGProps } from "react"
import ThemedImage from "./ui/ThemedImage";
export const DatabaseLogo = (props: SVGProps<SVGSVGElement>) => (
  <ThemedImage
    lightSrc={"https://content.clearcompass.co/logos/logo-black-no-bg.svg"}
    darkSrc={"https://content.clearcompass.co/logos/logo-white-no-bg.svg"}
    alt="A preview of app"
    width={120}
    height={20}
    {...props}
  />
);
